import React from 'react'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'

// Components
import Image from 'components/shared/ImageGatsby'
import SubTitle from 'components/elements/SubTitle'
import CustomLink from 'components/shared/CustomLink'

const StyledImage = styled(Image)`
  max-height: 300px;
  position: relative;
`

const Link = styled(CustomLink)`
  position: absolute;
  bottom: 25px;
  left: 40px;
  right: 40px;
  z-index: 1;
  background-color: #ffffff;
  border-radius: 5px;
  height: 45px;
  padding: 0 15px;

  font-family: ${(props) => props.theme.font.family.title};
  font-size: ${(props) => props.theme.font.size['18']};
  font-weight: 700;
  color: ${(props) => props.theme.color.text.dark};
`

interface ImagesWithLinkProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ImagesWithLink
}

const ImagesWithLink: React.FC<ImagesWithLinkProps> = ({ fields }) => (
  <section className="my-5">
    <div className="container">
      <TitleDefault>{fields?.description}</TitleDefault>
      <SubTitle className="mb-4 mb-lg-5">{fields?.subtitle}</SubTitle>
      <div className="row">
        {fields?.imageWithLink?.map((edge, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-md-6 mb-3 mb-lg-5" key={index}>
            <StyledImage image={edge?.image} alt="Lofthus" loading="lazy" />
            <Link
              to={edge?.link?.url || ''}
              className="d-flex align-items-center justify-content-between"
            >
              <span>{edge?.link?.title}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="99"
                height="8"
                viewBox="0 0 99 8"
                fill="none"
              >
                <path
                  d="M98.3536 4.35356C98.5488 4.1583 98.5488 3.84172 98.3536 3.64646L95.1716 0.464474C94.9763 0.269212 94.6597 0.269212 94.4645 0.464474C94.2692 0.659737 94.2692 0.976319 94.4645 1.17158L97.2929 4.00001L94.4645 6.82844C94.2692 7.0237 94.2692 7.34028 94.4645 7.53554C94.6597 7.7308 94.9763 7.7308 95.1716 7.53554L98.3536 4.35356ZM-4.37114e-08 4.5L98 4.50001L98 3.50001L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                  fill="#FF6700"
                />
              </svg>
            </Link>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default ImagesWithLink
